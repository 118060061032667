<template>
    <base-card class="pa-4">
        <v-card-title class="pa-1 pl-3 font-weight-bold">Advance Filter</v-card-title>
        <v-row class="ma-0 pa-0">
            <v-col cols="3">
                <v-text-field
                    v-model="searchModel.su"
                    label="Suburb"
                    outlined
                ></v-text-field>
            </v-col>
            <v-col cols="3">
                <v-combobox hide-details="auto"
                    v-model.trim="selectedPt"
                    :items="paymentTypeLists"
                    small-chips
                    label="Payment type"
                    multiple
                    outlined
                >
                    <template v-slot:selection="{ item, index }">
                        <v-chip small close v-if="index <= 2" @click:close="selectedPt.splice(index, 1)" class>
                            <span class="text-capitalize">{{ item.text }}</span>
                        </v-chip>
                        <span v-if="index === 3" class="grey--text text-caption">
                            (+{{ selectedPt.length - 3 }} others)
                        </span>
                    </template>
                </v-combobox>
            </v-col>
            <v-col cols="3">
                <v-combobox hide-details="auto"
                    v-model="searchModel.s"
                    :items="sourceLists"
                    small-chips
                    label="Source"
                    multiple
                    outlined
                >
                    <template v-slot:selection="{ item, index }">
                        <v-chip close small @click:close="searchModel.s.splice(index, 1)">
                            <span class="text-capitalize">{{ item }}</span>
                        </v-chip>
                    </template>
                </v-combobox>
            </v-col>
            <v-col cols="3">
                <v-menu v-model="menu1" transition="scale-transition"
                    :close-on-content-click="false" offset-y >
                    <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                            <v-tooltip top :disabled="targetDateClr">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field hide-details="auto" @input="clearDate($event, 'targetDateClr', 'targetDate')"
                                        :value="targetDateText"
                                        label="Target Period"
                                        v-bind="attrs"
                                        v-on="on"
                                        outlined
                                    ></v-text-field>
                                </template>
                                <span>{{targetDateTooltip}}</span>
                            </v-tooltip>
                        </div>
                    </template>
                    <div class="range-calendar">
                        <vc-date-picker mode="dateTime" class="date-picker-range" v-model="targetDate" is-range :masks="{ L: 'YYYY-MM-DD HH:mm:ss' }"
                            timezone="Australia/Melbourne" color="indigo" :model-config="{type: 'string', mask: 'YYYY-MM-DD HH:mm:ss' }"/>
                        <v-btn class="ma-2"  dark color="primary" @click="targetDateClr=menu1=false;">
                            Ok
                        </v-btn>
                    </div>
                </v-menu>
            </v-col>
        </v-row>
        <v-row class="ma-0 pa-0">
            <v-col cols="3">
                <v-menu v-model="menu" transition="scale-transition"
                    :close-on-content-click="false" offset-y >
                    <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                            <v-tooltip top :disabled="donateDateClr">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field hide-details="auto" @input="clearDate($event, 'donateDateClr', 'donateDate')"
                                        :value="donateDateText"
                                        label="Donation date/time"
                                        v-bind="attrs"
                                        v-on="on"
                                        outlined
                                    ></v-text-field>
                                </template>
                                <span>{{donateDateTooltip}}</span>
                            </v-tooltip>
                        </div>
                    </template>
                    <div class="range-calendar">
                        <vc-date-picker mode="dateTime" class="date-picker-range" v-model="donateDate" is-range :masks="{ L: 'YYYY-MM-DD HH:mm:ss' }"
                            timezone="Australia/Melbourne" color="indigo" :model-config="{type: 'string', mask: 'YYYY-MM-DD HH:mm:ss' }"/>                           
                        <v-btn class="ma-2"  dark color="primary" @click="donateDateClr=menu=false;">
                            Ok
                        </v-btn>
                    </div>
                </v-menu>
            </v-col>
            <v-col cols="3">
                <v-select hide-details="auto" v-model="searchModel.f" item-value="value" item-text="type"
                    :items="paymentModeLists" label="Payment Mode"
                    placeholder="-- Select Payment Mode --"
                    outlined :menu-props="{ bottom: true, offsetY: true }"
                ></v-select>
            </v-col>
            <v-col cols="3">
                <v-select hide-details="auto" v-model="searchModel.on_air" item-value="value" item-text="type"
                    :items="donateModeLists" label="On/Off Air"
                    placeholder="-- Select On/Off Air --"
                    outlined :menu-props="{ bottom: true, offsetY: true }"
                ></v-select>
            </v-col>
            <v-col cols="3">
                <v-select hide-details="auto" v-model="searchModel.md" item-value="value" item-text="type"
                    :items="majorDonorLists" label="Major Donor"
                    placeholder="-- Select Major Donor --"
                    outlined :menu-props="{ bottom: true, offsetY: true }"
                ></v-select>
            </v-col>
            <v-col cols="3">
                <v-subheader class="pa-0 h-auto">Amount</v-subheader>
                <v-range-slider
                    v-model="amount"
                    max="1000000"
                    min="0"
                    hide-details
                    class="align-center"
                >
                    <template v-slot:prepend v-if="amount && (amount[0] || amount[0] == 0)">
                        <v-subheader class="pa-0 h-auto ">{{amount[0]}}</v-subheader>
                    </template>
                    <template v-slot:append v-if="amount && amount[1]">
                        <v-subheader class="pa-0 h-auto ">{{amount[1]}}</v-subheader>
                    </template>
                </v-range-slider>
            </v-col>
            <v-col cols="3">
                <v-checkbox dense
                    v-model="searchModel.if"
                    label="Tally Donations"
                    color="primary"
                    hide-details
                    :false-value="0"
                    :true-value="1"
                ></v-checkbox>
            <!-- </v-col>
            <v-col cols="3"> -->
                    <v-checkbox dense
                        v-model="searchModel.i"
                        label="Incentive Donations"
                        color="primary"
                        :false-value="0"
                        :true-value="1"
                        hide-details
                    ></v-checkbox>
            </v-col>
            <v-col cols="3">
                <v-checkbox dense
                    v-model="searchModel.st"
                    label="Paid Only"
                    color="primary"
                    :false-value="0"
                    :true-value="1"
                    hide-details
                ></v-checkbox>
          <!-- </v-col> -->
          <!-- <v-col cols="3"> -->
                <v-checkbox dense
                    v-model="searchModel.ac"
                    label="Active Campaign"
                    color="primary"
                    :false-value="0"
                    :true-value="1"
                    hide-details
                ></v-checkbox>
            </v-col>
        </v-row>
        <v-card-actions style="cursor:pointer">
            <v-btn type="button" class="ma-2" dark color="info" @click="resetModel()">
                <v-icon left>mdi-refresh</v-icon>
                Reset
            </v-btn>
            <v-btn type="button" class="ma-2" dark color="primary" @click="searchFilter">
                <v-icon small left>mdi-account-search-outline</v-icon>
                Search
            </v-btn>
        </v-card-actions>
    </base-card>
</template>

<script>
export default {
    props: ['clearAdvFilter'],
    emits: ['reset', 'search'],
    data() {
        return {
            paymentTypeLists: [{text: 'Credit Card', value: 'CC'}, {text: 'Direct Debit', value: 'DD'}, {text: 'Cash', value: 'C'},
                {text: 'Cheque', value: 'CQ'}, {text: 'Direct Transfer', value: 'DT'}],
            sourceLists: ['Call Centre', 'Web Donation Form'],
            paymentModeLists: [{type: 'All', value: ''}, {type: 'Once', value: 'O'}, {type: 'Monthly', value: 'M'}],
            donateModeLists: [{type: 'All', value: ''}, {type: 'On-Air', value: '1'}, {type: 'Off-Air', value: '0'}],
            majorDonorLists: [{type: 'All', value: ''}, {type: 'Major Donor', value: '1'}, {type: 'Non Major Donor', value: '0'}],
            targetDate: {
                start: null,
                end: null,
            },
            donateDate: {
                start: null,
                end: null,
            },
            searchModel: {
                pt: [],
                s: [],
            },
            amount: [0, 1000000],
            menu: false,
            menu1: false,
            targetDateClr: true,
            donateDateClr: true,
            selectedPt: []
        }
    },
    computed: {
        targetDateText () {
            return this.targetDateClr ? '' : `${this.$helpers.getMomentDatas('YYYY-MM-DD hh:mm A', this.targetDate.start)}-${this.$helpers.getMomentDatas('YYYY-MM-DD hh:mm A', this.targetDate.end)}`;
        },
        targetDateTooltip () {
            return `${this.$helpers.getMomentDatas('YYYY-MM-DD hh:mm A', this.targetDate.start)}  to  ${this.$helpers.getMomentDatas('YYYY-MM-DD hh:mm A', this.targetDate.end)}`;
        },
        donateDateText () {
            return this.donateDateClr ? '' : `${this.$helpers.getMomentDatas('YYYY-MM-DD hh:mm A', this.donateDate.start)}-${this.$helpers.getMomentDatas('YYYY-MM-DD hh:mm A', this.donateDate.end)}`;
        },
        donateDateTooltip () {
            return `${this.$helpers.getMomentDatas('YYYY-MM-DD hh:mm A', this.donateDate.start)}  to  ${this.$helpers.getMomentDatas('YYYY-MM-DD hh:mm A', this.donateDate.end)}`;
        },
    },
    watch: {
        'clearAdvFilter': function(newVal) {
            if (newVal) {
                this.resetModel('clear')
            }
        }
    },
    mounted() {
        this.targetDate = this.donateDate = {
            start:  this.$helpers.getJsDateBeginEnd('start'),
            end: this.$helpers.getJsDateBeginEnd(),
        }
    },
    methods: {
        resetModel(clearAdv) {
            this.searchModel = {
                s: []
            };
            this.amount = [0, 1000000];
            this.selectedPt = [],
            this.donateDateClr = this.targetDateClr = true;
            this.targetDate = this.donateDate ={
                start:  this.$helpers.getJsDateBeginEnd('start'),
                end: this.$helpers.getJsDateBeginEnd(),
            }
            if (!clearAdv) {
                this.$emit('reset');
            }
        },
        searchFilter() {
            this.searchModel.fa = this.amount[0];
            this.searchModel.ta = this.amount[1];
            const paymentType = this.selectedPt.map(item => item.value);
            this.searchModel.pt = paymentType.join(',');
            if (!this.targetDateClr) {
                this.searchModel.tsd = this.$helpers.getMomentDatas('DD-MM-YYYY hh:mm A', this.targetDate.start);
                this.searchModel.ted = this.$helpers.getMomentDatas('DD-MM-YYYY hh:mm A', this.targetDate.end);
            }
            if (!this.donateDateClr) {
                this.searchModel.sd = this.$helpers.getMomentDatas('DD-MM-YYYY hh:mm A', this.donateDate.start);
                this.searchModel.ed = this.$helpers.getMomentDatas('DD-MM-YYYY hh:mm A', this.donateDate.end);
            }
            this.$emit('search', this.searchModel);
        },
        clearDate(evt, field, dateField){
            if(!evt) {
                this[field] = true;
                this[dateField] = {
                    start:  this.$helpers.getJsDateBeginEnd('start'),
                    end: this.$helpers.getJsDateBeginEnd(),
                };
            }
        }
    }
}
</script>